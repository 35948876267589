import React, { useState, useEffect } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import "./currentWeekTable.css";

export default function CurrentWeekTable(props: any) {
  const [mondayDate, setMondayDate] = useState("");
  const [tuesdayDate, setTuesdayDate] = useState("");
  const [wednesdayDate, setWednesdayDate] = useState("");
  const [thursdayDate, setThursdayDate] = useState("");
  const [fridayDate, setFridayDate] = useState("");

  const convertDate = (date: Date, change: number) => {
    let convertedDate = new Date(date);
    convertedDate.setDate(convertedDate.getDate() - change);
    return convertedDate.toString().slice(0, 10).replace(/\s/g, "");
  };

  let startDate = new Date(new Date().getFullYear(), 0, 1);
  let days = Math.floor(
    (Number(new Date()) - Number(startDate)) / (24 * 60 * 60 * 1000)
  );
  let weekNumber = Math.ceil(days / 7);
  let pushUpsGoal = 5 * weekNumber - 135;

  useEffect(() => {
    let todaysWeekDay = new Date().toString().slice(0, 3);
    let today = new Date();

    if (todaysWeekDay === "Mon") {
      setMondayDate(convertDate(today, 0));
    } else if (todaysWeekDay === "Tue") {
      setTuesdayDate(convertDate(today, 0));
      setMondayDate(convertDate(today, 1));
    } else if (todaysWeekDay === "Wed") {
      setWednesdayDate(convertDate(today, 0));
      setTuesdayDate(convertDate(today, 1));
      setMondayDate(convertDate(today, 2));
    } else if (todaysWeekDay === "Thu") {
      setThursdayDate(convertDate(today, 0));
      setWednesdayDate(convertDate(today, 1));
      setTuesdayDate(convertDate(today, 2));
      setMondayDate(convertDate(today, 3));
    } else if (todaysWeekDay === "Fri") {
      setFridayDate(convertDate(today, 0));
      setThursdayDate(convertDate(today, 1));
      setWednesdayDate(convertDate(today, 2));
      setTuesdayDate(convertDate(today, 3));
      setMondayDate(convertDate(today, 4));
    } else if (todaysWeekDay === "Sat") {
      setFridayDate(convertDate(today, 1));
      setThursdayDate(convertDate(today, 2));
      setWednesdayDate(convertDate(today, 3));
      setTuesdayDate(convertDate(today, 4));
      setMondayDate(convertDate(today, 5));
    } else if (todaysWeekDay === "Sun") {
      setFridayDate(convertDate(today, 2));
      setThursdayDate(convertDate(today, 3));
      setWednesdayDate(convertDate(today, 4));
      setTuesdayDate(convertDate(today, 5));
      setMondayDate(convertDate(today, 6));
    }
  }, [props.data]);

  return (
    <>
      <table className="currentWeekTable">
        <thead>
          <tr>
            <th className="name">Name</th>
            <th>Mon</th>
            <th>Tue</th>
            <th>Wed</th>
            <th>Thu</th>
            <th>Fri</th>
          </tr>
        </thead>
        <tbody>
          {props.data
            ?.sort((a: any, b: any) => a.creationDate - b.creationDate)
            .map((item: any) => {
              return (
                <tr key={item.name}>
                  <td>{item.name}</td>
                  <td>
                    {item[mondayDate] >= pushUpsGoal ? (
                      <CheckRoundedIcon color="success" />
                    ) : (
                      <ClearRoundedIcon color="error" />
                    )}
                  </td>
                  <td>
                    {item[tuesdayDate] >= pushUpsGoal ? (
                      <CheckRoundedIcon color="success" />
                    ) : (
                      <ClearRoundedIcon color="error" />
                    )}
                  </td>
                  <td>
                    {item[wednesdayDate] >= pushUpsGoal ? (
                      <CheckRoundedIcon color="success" />
                    ) : (
                      <ClearRoundedIcon color="error" />
                    )}
                  </td>
                  <td>
                    {item[thursdayDate] >= pushUpsGoal ? (
                      <CheckRoundedIcon color="success" />
                    ) : (
                      <ClearRoundedIcon color="error" />
                    )}
                  </td>
                  <td>
                    {item[fridayDate] >= pushUpsGoal ? (
                      <CheckRoundedIcon color="success" />
                    ) : (
                      <ClearRoundedIcon color="error" />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}
