import { useState } from "react";
import "./Modal.css";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { fontWeight } from "@mui/system";

export default function Modal(props: any) {
  const [modal, setModal] = useState(props.isVisible);

  const toggleModal = () => {
    props.number === 0 ? alert("Do some push ups") : setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <>
      <button className="addPushUps" onClick={toggleModal}>
        {props.buttonName}
      </button>
      {modal && (
        <div className="modal">
          <div className="overlay" onClick={toggleModal}></div>
          <div className="modal-content">
            <ClearRoundedIcon
              color="error"
              onClick={toggleModal}
              className="closeModal"
            />
            <h2 className="title">{props.title}</h2>
            <div className="content">
              {props.textStart}
              <span className="bold">{props.number}</span> {props.textEnd}
            </div>
            <div className="actionButtons">
              <div
                className="confirmButton"
                onClick={(e) => {
                  console.log(props.pushUpsCount);
                  props.updatePushUps(props.userEmail, props.pushUpsCount);
                  toggleModal();
                }}
              >
                Confirm <CheckRoundedIcon color="success" />
              </div>
              <div className="closeButton" onClick={toggleModal}>
                I didn't <ClearRoundedIcon color="error" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
