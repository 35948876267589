import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDL1TkdsV4cmoJnXF8lEfi1Kc0dGZcaH0M",
  authDomain: "resume-9bdc9.firebaseapp.com",
  projectId: "resume-9bdc9",
  storageBucket: "resume-9bdc9.appspot.com",
  messagingSenderId: "1000999063153",
  appId: "1:1000999063153:web:f7c556ba3d3a8ff26515d0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
 
export default db;
