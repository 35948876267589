import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import React, { useState, useEffect } from "react";
import "./App.css";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";
import { Value } from "react-calendar/dist/cjs/shared/types";
import CurrentWeekTable from "./components/currentWeekTable/currentWeekTable";
import db from "../src/firebase_setup/firebase.js";
import {
  getFirestore,
  collection,
  addDoc,
  endBefore,
} from "firebase/firestore";
import axios from "axios";
import Modal from "./components/currentWeekTable/modal/modal";

export default function App() {
  const [value, setValue] = useState<Value>(new Date());
  const [data, setData] = useState<any[]>([]);
  const [number, setNumber] = useState<number>(0);
  const [additionalNumber, setAdditionalNumber] = useState<number>(0);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");

  const [logoutVisible, setLogoutVisible] = useState(false);
  const [today, setToday] = useState<string>(
    new Date().toString().slice(0, 10).replace(/\s/g, "")
  );

  const dataFetch = async () => {
    await db
      .collection("pushUpsCount")
      .get()
      .then((snapshot) => {
        setData([]);
        snapshot.forEach((map) => {
          const duomenys = map.data();
          setData((old) => [...old, duomenys]);
        });
      });
  };

  useEffect(() => {
    dataFetch();
  }, []);

  const updatePushUps = async (email: string, pushUpsCount?: number) => {
    await db
      .collection("pushUpsCount")
      .doc(email)
      .update({
        [Date().valueOf().slice(0, 10).replace(/\s/g, "")]: pushUpsCount,
      });
    await dataFetch();
  };

  const createDBDocument = async (name: string) => {
    var docRef = db.collection("pushUpsCount").doc(name);
    docRef
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          await db.collection("pushUpsCount").doc(name).update(profile);
        } else {
          // doc.data() will be undefined in this case
          await db.collection("pushUpsCount").doc(name).set(profile);
          await db
            .collection("pushUpsCount")
            .doc(name)
            .update({ creationDate: new Date() });
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    await db.collection("pushUpsCount").doc(name).update(profile);
  };

  const inputUpdate = (value: number) => {
    if (isNaN(value)) {
    } else if (value < 0) {
      setNumber(1);
    } else if (value > 999) {
      setNumber(999);
    } else {
      setNumber(value);
    }
  };
  const additionalInputUpdate = (value: number) => {
    if (isNaN(value)) {
    } else if (value < 0) {
      setAdditionalNumber(1);
    } else if (value > 999) {
      setAdditionalNumber(999);
    } else {
      setAdditionalNumber(value);
    }
  };

  //Google authorisation
  const [user, setUser] = useState<any>([]);
  const [profile, setProfile] = useState<any>(null);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };
  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          setUserName(res.data.name);
          setEmail(res.data.email);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    if (!!profile) {
      createDBDocument(profile.email);
    }
  }, [profile]);

  const selectAll = (e: any) => {
    e.target.select();
  };

  function onChange(nextValue: Value) {
    setValue(nextValue);
    let a = nextValue?.toString().slice(0, 10).replace(/\s/g, "");
    if (!!a) {
      setSelectedDate(a);
    }
  }

  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toString().slice(0, 10).replace(/\s/g, "")
  );

  let currentDate = new Date();
  let startDate = new Date(currentDate.getFullYear(), 0, 0);
  let days = Math.floor(
    (Number(value) - Number(startDate)) / (24 * 60 * 60 * 1000)
  );

  let weekNumber = Math.ceil(days / 7);
  let pushUpsGoal = 5 * weekNumber - 135;

  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState<any[]>([data]);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const pages: number[] = [];
  const itemsPerPage = 5;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [currentPage, data]);
  //itemOffset, itemsPerPage, data
  const handlePageClick = (event: any) => {
    const newOffset = (event * itemsPerPage) % data.length;
    setCurrentPage(event);
    setItemOffset(newOffset);
  };

  const sortWithNull = (ascending: boolean, value: string) => {
    return function (a: any, b: any) {
      if (value in a && value in b) {
        if (ascending) {
          //check if sort if ascending or descending
          return a[value] < b[value] ? -1 : 1;
        }
        return a[value] < b[value] ? 1 : -1;
      } else if (value in a) {
        // Sort objects with value first
        return -1;
      } else if (value in b) {
        // Sort objects with value first
        return 1;
      } else {
        // If neither object has value property, maintain the original order
        return 0;
      }
    };
  };

  const generatePages = () => {
    let n = pageCount;
    for (let i = 0; i < n; i++) {
      pages.push(i);
    }
    return (
      <div className="pageinationPage">
        {pages.map((page, index) => {
          if (n === 1) {
            return <></>;
          }
          if (index === 0) {
            return (
              <>
                <div
                  className={`pageButton notSelectedPage `}
                  onClick={() => handlePageClick(0)}
                >
                  {"<<"}{" "}
                </div>
                <div
                  className={`pageButton notSelectedPage `}
                  onClick={() =>
                    handlePageClick(currentPage > 0 ? currentPage - 1 : 0)
                  }
                >
                  {"<"}
                </div>
                <div
                  className={`pageButton ${
                    index === currentPage ? "colorWhite" : "notSelectedPage"
                  }`}
                  onClick={() => handlePageClick(index)}
                >
                  {page + 1}
                </div>
              </>
            );
          }
          if (index === n - 1) {
            return (
              <>
                <div
                  className={`pageButton ${
                    index === currentPage ? "colorWhite" : "notSelectedPage"
                  }`}
                  onClick={() => handlePageClick(index)}
                >
                  {page + 1}{" "}
                </div>
                <div
                  className={`pageButton notSelectedPage `}
                  onClick={() =>
                    handlePageClick(
                      currentPage < n - 1 ? currentPage + 1 : n - 1
                    )
                  }
                >
                  {">"}
                </div>
                <div
                  className={`pageButton notSelectedPage `}
                  onClick={() => handlePageClick(n - 1)}
                >
                  {">>"}
                </div>
              </>
            );
          }
          if (n > 7 && index === 3) {
            return <div className={`pageButton `}>{"..."}</div>;
          }
          if (n > 7 && n - index < 3) {
            return (
              <div
                className={`pageButton ${
                  index === currentPage ? "colorWhite" : "notSelectedPage"
                }`}
                onClick={() => handlePageClick(index)}
              >
                {page + 1}
              </div>
            );
          }
          if (n > 7 && index > 2) {
            return <></>;
          }
          return (
            <div
              className={`pageButton ${
                index === currentPage ? "colorWhite" : "notSelectedPage"
              }`}
              onClick={() => handlePageClick(index)}
            >
              {page + 1}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="App">
      <div className="test">
        {profile ? (
          <div>
            <div className="userHeader">
              <div className="label">Have you done any push ups today?</div>
              <div className="scoreUpdate">
                <input
                  className="numberInput"
                  value={number}
                  type="text"
                  pattern="[0-9]"
                  onChange={(e) => inputUpdate(Number(e.target.value))}
                  onClick={selectAll}
                  min="0"
                  max="999"
                  inputMode="numeric"
                ></input>
                <Modal
                  isVisible={false}
                  title={`${userName},`}
                  text={`Are you sure you have done ${number} push ups today??`}
                  textStart={`Are you sure you have done `}
                  textEnd={`push ups today??`}
                  number={number}
                  buttonName={"Add"}
                  pushUpsCount={number}
                  userEmail={profile.email}
                  updatePushUps={updatePushUps}
                />
              </div>
              <div className="label">Done some more?</div>
              <div className="scoreUpdate">
                <input
                  className="numberInput"
                  value={additionalNumber}
                  type="text"
                  pattern="[0-9]"
                  onChange={(e) =>
                    additionalInputUpdate(Number(e.target.value))
                  }
                  onClick={selectAll}
                  min="0"
                  max="999"
                  inputMode="numeric"
                ></input>
                <Modal
                  isVisible={false}
                  title={`${userName},`}
                  text={`Have you done ${additionalNumber} more push ups today??`}
                  textStart={`Have you done `}
                  textEnd={` more push ups today??`}
                  number={additionalNumber}
                  buttonName={"Update"}
                  pushUpsCount={
                    currentItems.find((x) => x.email === email)[today]
                      ? currentItems.find((x) => x.email === email)[today] +
                        additionalNumber
                      : additionalNumber
                  }
                  additionalpushUpsCount={additionalNumber}
                  userEmail={profile.email}
                  updatePushUps={updatePushUps}
                />
              </div>
              <div className="userInfo">
                <img
                  src={profile.picture}
                  alt="user logo"
                  className="userImage"
                  onClick={() => {
                    setLogoutVisible(!logoutVisible);
                  }}
                />
                <div
                  className={`logOutButton ${
                    logoutVisible ? "logOutButton" : "logoutNotVisible"
                  }`}
                  onClick={logOut}
                >
                  Log out
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="logInHeader">
            <div className="label">Wanna join?</div>
            <div className="googleLogIn" onClick={() => login()}>
              Sign in with Google 🚀{" "}
            </div>
          </div>
        )}
      </div>
      <div className="pageLayout">
        <div className="FirstGround">
          <Calendar onChange={onChange} value={value} className="callendar" />
          <div className="scoreboard">
            <div className="title">Scoreboard </div>

            {currentItems
              .sort(sortWithNull(false, selectedDate))
              .map((element) => (
                <div className="score">
                  <div className="name">{element.name}</div>
                  <div className="pushUpsCount">
                    <div
                      className={`${
                        element[selectedDate] >= pushUpsGoal
                          ? "colorGreen"
                          : "colorRed"
                      }`}
                    >
                      {!!element[selectedDate] ? element[selectedDate] : 0}
                    </div>
                    /{pushUpsGoal}
                  </div>{" "}
                </div>
              ))}

            <div className="pagination">{generatePages()}</div>
          </div>
        </div>
        <div className="SecondGround">
          <div className="title">Current week results:</div>
          <div className="currentWeekTable">
            <CurrentWeekTable data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
